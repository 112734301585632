import { Box, Button, Grid } from "@mui/material";
import Logo from "../../images/logo/logoOne.png";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import "../Challenger.css";
import "./ScrollBar.css";

const EmailChallengerOne = ({ props }) => {
  const [name, setName] = useState(props.recipientName);
  const [clientName, setClientName] = useState({
    firstName: props.senderFirstName,
    lastName: props.senderLastName,
  });

  const [terms, setTerms] = useState(props.terms);
  const [acceptedTerms, setAcceptedTerms] = useState(props.acceptedTerms);

  useEffect(() => {
    setTerms(props.terms);
  }, [props]);

  async function postTerms(e) {
    try {
      const url = `${process.env.REACT_APP_API_ROOT_URL}/challenger/verification/termsandconditions`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: props.token }),
        // body: { token: token },
      });

      await response.json();
    } catch (err) {
      console.log("Failed to accept terms and conditions", { err });
    }
  }

  return (
    <>
      <Box
        className="items"
        style={{ background: "#FFF" }}
        sx={{
          boxShadow: 3,
          borderRadius: 6,
          alignSelf: "center",
          width: "90%",
        }}
      >
        <div
          className="items"
          style={{
            margin: "50px 0px 50px 0px",
          }}
        >
          <img src={Logo} alt="LOGO" />

          <h1 className="steps">{props.challengeOneScript.introMessage}</h1>
          <h2 className="desc">
            {props.challengeOneScript.senderMessage} <br />{" "}
            {props.challengeOneScript.minuteMessage}
          </h2>

          <h2 className="desc2">{props.challengeOneScript.termsHeader}</h2>

          <Box
            sx={{
              boxShadow: 2,
              width: "80%",
              height: "40%",
              borderRadius: 6,

              marginTop: "15px",
            }}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                margin: "0px 0px 25px 0px",
              }}
            >
              <Grid
                container
                direction="column"
                alignItems="left"
                justifyContent="left"
                sx={{
                  textAlign: "left",
                  width: "90%",
                }}
              >
                <h1
                  style={{
                    fontSize: "1em",
                    color: "#002B36",
                    textAlign: "left",
                  }}
                >
                  {props.challengeOneScript.termsLabel}
                </h1>
                <h2 className={"agreement"}>
                  {props.challengeOneScript.yourAgreement}
                </h2>
                <Grid container>
                  <Grid item style={{ maxWidth: "700px", margin: "0px auto" }}>
                    <pre className="scrollbar">{terms}</pre>
                  </Grid>
                </Grid>
                <p>{props.challengeOneScript.beforeSubmit}</p>
              </Grid>

              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="right"
                sx={{
                  width: "90%",
                }}
              >
                <Grid item>
                  <Button
                    onClick={(e) => {
                      setAcceptedTerms(true);
                      postTerms(e);
                    }}
                    disableElevation
                    variant="contained"
                    disabled={acceptedTerms}
                    sx={{
                      bgcolor: "#002129",
                      "&:hover": { bgcolor: "#000" },
                      fontFamily: "LugfaRegular",
                      textTransform: "none",
                    }}
                  >
                    {props.challengerButtonsScript.accept}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Button
            disableElevation
            variant="contained"
            size="large"
            disabled={acceptedTerms === false}
            sx={{
              width: "60%",
              bgcolor: "#002129",
              "&:hover": { bgcolor: "#000" },
              margin: "50px 0px 0px 0px",
              fontFamily: "LugfaRegular",
              textTransform: "none",
            }}
            onClick={() => {
              props.setAcceptedTerms(true);
              props.setCurrentPage(3);
            }}
          >
            {props.challengeOneScript.navNext}
          </Button>
        </div>
      </Box>
    </>
  );
};

export default EmailChallengerOne;
