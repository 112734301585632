import { Box, Button, Grid, TextField, useMediaQuery } from "@mui/material";
import Logo from "../../images/logo/logoOne.png";
import { useState } from "react";
import CountrySelect from "./CountrySelect";

const EmailChallengerThree = ({ props }) => {
  const [zipCode, setZipCode] = useState(props.zipCode);
  const [countryCode, setCountryCode] = useState(props.countryCode);

  const queryWidth = "800px";

  let textFieldStyle;
  if (useMediaQuery(`(max-width: ${queryWidth})`)) {
    // mobile
    textFieldStyle = "small";
  } else {
    // desktop
    textFieldStyle = "medium";
  }

  return (
    <>
      <Box
        className="items"
        sx={{
          boxShadow: 8,
          borderRadius: 6,
          alignSelf: "center",
          width: "80%",
        }}
      >
        <div
          className="items"
          style={{
            margin: "50px 0px 50px 0px",
          }}
        >
          <img src={Logo} alt="LOGO" />

          <h1 className="steps">
            {props.challengerButtonsScript.step} 2{" "}
            {props.challengerButtonsScript.of} 3
          </h1>
          <h2 className="desc">
            {props.challengeThreeScript.zipCodeHeaderOne} <br />
            <span
              style={{
                fontWeight: 900,
                color: "#f2800a",
              }}
            >
              {props.challengeThreeScript.zipCodeHeaderTwo.toUpperCase()}
            </span>
          </h2>

          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item className="grid">
              <h2 className="names">{props.challengeThreeScript.zipCode}</h2>
              <TextField
                fullWidth
                id="zip"
                variant="outlined"
                // defaultValue={props.zipCode}
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                size={textFieldStyle}
              />
            </Grid>
            <Grid item className="grid">
              <h2 className="names">Country Code</h2>
              <CountrySelect
                setCountryCode={setCountryCode}
                countryCode={countryCode}
              />
            </Grid>

            <Grid item className="grid">
              <Button
                disableElevation
                variant="contained"
                size="large"
                fullWidth
                disabled={zipCode === "" || countryCode === ""}
                sx={{
                  bgcolor: "#002129",
                  "&:hover": { bgcolor: "#000" },
                  margin: "35px 0px 0px 0px",
                  fontFamily: "LugfaRegular",
                  textTransform: "none",
                }}
                onClick={() => {
                  props.setZipCode(zipCode);
                  props.setCountryCode(countryCode);
                  props.setCurrentPage(5);
                }}
              >
                {props.challengerButtonsScript.confirm}
              </Button>
              <Button
                disableElevation
                variant="text"
                size="large"
                fullWidth
                sx={{
                  margin: "25px 0px 0px 0px",
                  color: "#002129",
                  fontFamily: "LugfaRegular",
                  textTransform: "none",
                }}
                style={{ backgroundColor: "transparent" }}
                onClick={() => props.setCurrentPage(3)}
              >
                <u>{props.challengerButtonsScript.previousStep}</u>
              </Button>
            </Grid>
          </Grid>
        </div>
      </Box>
    </>
  );
};

export default EmailChallengerThree;
